<template>
  <DataTable
    id="serviceTable"
    ref="table"
    :fields="fields"
    :opts="options"
    :select-checkbox="0"
    :details="details"
    :column-search="true"
    :hide-tfoot="false"
    @edit="doAlertEdit"
    @delete="doAlertDelete"
    @reloaded="doAfterReload"
    @table-creating="doCreating"
    @table-created="doCreated"
  >
  </DataTable>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { serverUrl } from '@/global/variables';

export default {
  name: 'Service23Table',

  components: {
    DataTable,
  },

  data: (vm) => ({
    options: {
      ajax: {
        url: serverUrl('/api/smart-table/23'),
        beforeSend(request) {
          request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
        },
        dataSrc(json) {
          vm.$emit('loaded:data')
          return json.data
        }
      },
    //  buttons: ['copy', 'excel', 'csv', 'print', 'colvis'],
    buttons: ['copy',
      // 'excel',
       {
                 extend: 'excelHtml5',
                  title: 'Apple Carrier Check (S2)' + new Date().toLocaleString(),
                 autoFilter: true,
                 exportOptions: {
                     columns: ':visible'
                 }
             },
            // 'csv', 
              {
                 extend: 'csvHtml5',
                  title: 'Apple Carrier Check (S2)' + new Date().toLocaleString(),
                 exportOptions: {
                     columns: ':visible'
                 }
             },
       'print', 'colvis'],
      /*eslint-disable */
      dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'pl>>",
      /*eslint-enable */
      lengthMenu: [ [50, 100, 500, 1000, 2500, 5000, 10000,  -1], [50, 100, 500, 1000, 2500, 5000, 10000, 'All'] ],
      pageLength: 50,
      responsive: false,
      scrollX: true,
      processing: true,
      searching: true,
      searchDelay: 1500,
      destroy: true,
      ordering: true,
      lengthChange: true,
      serverSide: true,
      fixedHeader: true,
      stateSave: true
    },
    fields: {
     order_id: { label: 'Id', sortable: true, searchable: true, order: 'desc'  },
     model: { label: 'MODEL', sortable: true },
     modelDesc: { label: 'MODEL DESC', sortable: true },
     imei: { label: 'IMEI', sortable: true, copyable: true },
     imei2: { label: 'IMEI2', sortable: true },
     serial: { label: 'SERIAL', sortable: true },
     estPurchaseDate: { label: 'PURCHASE DATE', sortable: true },
     carrier: { label: 'CARRIER', sortable: true },
     country: { label: 'COUNTRY', sortable: true },
     simlock: { label: 'SIMLOCK', sortable: true },
     created_at: { label: 'ORDER CREATED AT', sortable: true },
    },
    details: false,
  }),

  methods: {
    doAlertEdit(data) {
      window.alert(`Row edit click for item ID: ${data.id}`)
    },
    doAlertDelete(data, row, tr, target) {
      window.alert(`Deleting item ID: ${data.id}`)
      // row.remove() doesn't work when serverside is enabled
      // so we fake it with dom remove
      tr.remove()
      const table = this.$refs.table
      setTimeout(() => {
        // simulate extra long running ajax
        table.reload()
      }, 1500)
    },
    doAfterReload(data, table) {
      window.alert('data reloaded')
    },
    doCreating(comp, el) {
      // console.log('creating')
    },
    doCreated(comp) {
      // console.log('created')
    },
    doSearch(v) {
      this.$refs.table.search(v)
    },
  },
}
</script>
