<template>
  <DataTable
    id="serviceTable"
    ref="table"
    :fields="fields"
    :opts="options"
    :select-checkbox="0"
    :details="details"
    :column-search="true"
    :hide-tfoot="false"
    @edit="doAlertEdit"
    @delete="doAlertDelete"
    @reloaded="doAfterReload"
    @table-creating="doCreating"
    @table-created="doCreated"
    @copy="$emit('copy', $event)"
  >
    <!-- <template slot="HEAD__details_control">
      <b>Show Details</b>
    </template>
    <template
      slot="address2"
      slot-scope="ctx"
    >
      <span>{{ ctx.data.city }}, {{ ctx.comp.formatCode(ctx.data.zipcode) }}</span>
    </template>
    <template
      slot="_details"
      slot-scope="ctx"
    >
      <strong>I'm a child for {{ ctx.data.id }} yall</strong>
    </template> -->
  </DataTable>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import { serverUrl } from "@/global/variables";

export default {
  name: "Service37Table",

  components: {
    DataTable,
  },

  data: (vm) => ({
    options: {
      ajax: {
        url: serverUrl("/api/smart-table/37"),
        beforeSend(request) {
          request.setRequestHeader(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
        },
        dataSrc(json) {
          vm.$emit("loaded:data");
          return json.data;
        },
      },
      //  buttons: ['copy', 'excel', 'csv', 'print', 'colvis'],
      buttons: [
        "copy",
        // 'excel',
        {
          extend: "excelHtml5",
          title: "XIAOMI MI LOCK & INFO (S2)" + new Date().toLocaleString(),
          autoFilter: true,
          exportOptions: {
            columns: ":visible",
          },
        },
        // 'csv',
        {
          extend: "csvHtml5",
          title: "XIAOMI MI LOCK & INFO (S2)" + new Date().toLocaleString(),
          exportOptions: {
            columns: ":visible",
          },
        },
        "print",
        "colvis",
      ],
      /*eslint-disable */
      dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'pl>>",
      /*eslint-enable */
      lengthMenu: [
        [50, 100, 500, 1000, 2500, 5000, 10000, -1],
        [50, 100, 500, 1000, 2500, 5000, 10000, "All"],
      ],
      pageLength: 50,
      responsive: false,
      scrollX: true,
      processing: true,
      searching: true,
      searchDelay: 1500,
      destroy: true,
      ordering: true,
      lengthChange: true,
      serverSide: true,
      fixedHeader: true,
      stateSave: true,
      order: [[0, "desc"]],
    },
    fields: {
      order_id: { label: "Id", sortable: true, searchable: true },
      orgIMEI: { label: "Search Term", sortable: true },
      description: { label: "Description", sortable: true },
      imei: { label: "IMEI", sortable: true, copyable: true },
      brand: { label: "Brand", sortable: true },
      model: { label: "Model", sortable: true },
      name: { label: "Name", sortable: true },
      purchaseCountry: { label: "Purchase Country", sortable: true },
      warrantyStatus: { label: "Warranty Status", sortable: true },
      warrantyStartDate: { label: "Warranty Start", sortable: true },
      warrantyEndDate: { label: "Warranty End", sortable: true },
      productionDate: { label: "Production Date", sortable: true },
      activationDate: { label: "Activation Date", sortable: true },
      miActivationLock: { label: "MI ActivationLock", sortable: true },
      created_at: { label: "Created at", sortable: true },
      // other examples
      // actions: {
      //   isLocal: true,
      //   label: 'Actions',
      //   defaultContent: '<a href="javascript:void(0);" data-action="edit" class="btn btn-primary btn-sm"><i class="mdi mdi-square-edit-outline"></i> Edit</a>' +
      //     '<span data-action="delete" class="btn btn-danger btn-sm"><i class="mdi mdi-delete"></i> Delete</span>'
      // },
      // name: { label: 'Name', sortable: true, searchable: true, defaultOrder: 'desc' },
      // username: { label: 'Username', sortable: false, searchable: true  },
      // email: { label: 'Email' },
      // address1: {
      //   label: 'Address1',
      //   data: 'address',
      //   template: '{{ data.street }}, {{ data.suite }}'
      // },
      // address2: {
      //   label: 'Address2',
      //   data: 'address'
      // },
      // phone: { label: 'Phone' },
      // website: {
      //   label: 'Website',
      //   render: (data) => {
      //     return `https://${ data }`
      //   }
      // }
    },
    details: false,
  }),

  methods: {
    // doLoadTable(cb) {
    //   $.getJSON(serverUrl('/api/smart-table/1'), function( data ) {
    //     cb(data)
    //   })
    // },
    doAlertEdit(data) {
      window.alert(`Row edit click for item ID: ${data.id}`);
    },
    doAlertDelete(data, row, tr, target) {
      window.alert(`Deleting item ID: ${data.id}`);
      // row.remove() doesn't work when serverside is enabled
      // so we fake it with dom remove
      tr.remove();
      const table = this.$refs.table;
      setTimeout(() => {
        // simulate extra long running ajax
        table.reload();
      }, 1500);
    },
    doAfterReload(data, table) {
      window.alert("data reloaded");
    },
    doCreating(comp, el) {
      // console.log('creating')
    },
    doCreated(comp) {
      // console.log('created')
    },
    doSearch(v) {
      this.$refs.table.search(v);
    },
    // doExport(type) {
    //   const parms = this.$refs.table.getServerParams()
    //   parms.export = type
    //   window.alert('GET /api/v1/export?' + jQuery.param(parms))
    // },
    // formatCode(zipcode) {
    //   return zipcode.split('-')[0]
    // }
  },
};
</script>
