<template>
  <div id="services">
    <b-row>
      <b-col class="mb-2">
        <p style="text-align:right;">ℹ️ Use the search boxes at the bottom of the page to display only the results you want.</p>
        <b-card>
          <b-form-group id="selectAService">
            <v-select
              v-model="selectedService"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="services"
              :reduce="s => s.value"
              placeholder="Select a service"
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card v-if="selectedService">
            <form
              id="searchForm"
              class="form-inline d-flex justify-content-end"
              @submit.stop.prevent="doSearch"
            >
              <div class="input-group">
                <input
                  v-model="quickSearch"
                  type="search"
                  placeholder="Quick search"
                  class="form-control form-control-sm"
                >
                <div class="input-group-append">
                  <button
                    type="submit"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    <i class="mdi mdi-magnify" /> Go
                  </button>
                </div>
              </div>
            </form>
            <component
              ref="table"
              :is="selectedService"
              @loaded:data="onLoadedData"
              @copy="doCopy"
            ></component>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import Service1Table from './Service1Table.vue'
import Service2Table from './Service2Table.vue'
import Service3XTable from './Service3XTable.vue'
import Service23Table from './Service23Table.vue'
import Service4Table from './Service4Table.vue'
import Service20Table from './Service20Table.vue'
import Service5Table from './Service5Table.vue'
import Service13Table from './Service13Table.vue'
import Service37Table from './Service37Table.vue'
import Service14Table from './Service14Table.vue'
import Service19Table from './Service19Table.vue'
import Service39Table from './Service39Table.vue'
import Service8Table from './Service8Table.vue'
import Service11Table from './Service11Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: 'smart-tables',

  components: {
    BAlert,
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BOverlay,
    Service1Table,
    Service2Table,
    Service3XTable,
    Service23Table,
    Service4Table,
    Service20Table,
    Service5Table,
    Service13Table,
    Service37Table,
    Service14Table,
    Service19Table,
    Service39Table,
    Service8Table,
    Service11Table,
    ToastificationContent,
    vSelect,
  },

  data: () => ({
    quickSearch: '',
    showOverlay: false,
    selectedService: null,
    services: [
      { label: 'Find My iPhone [ FMI ] (ON/OFF)', value: 'service-1-table' },
      { label: 'iCloud Clean/Lost Check', value: 'service-4-table' },
      { label: 'Warranty + Activation + Replaced (S2)', value: 'service-2-table' },
      { label: 'Apple Carrier Check (S2)', value: 'service-23-table' },
      { label: 'Apple SimLock Check', value: 'service-20-table' },
      { label: 'Apple Model + Color + GB', value: 'service-13-table' },
      { label: 'Apple FULL INFO (No Carrier/No SimLock)', value: 'service-3X-table' },
      { label: 'Apple FULL INFO + Carrier / SimLock (S2)', value: 'service-19-table' },
      { label: 'APPLE FULL INFO-NEW (+CARRIER)', value: 'service-39-table' },
      { label: 'Apple IMEI to SN (Full Convertor)', value: 'service-14-table' },
      { label: 'Blacklist Check (GSMA)(all models)', value: 'service-5-table' },
      { label: 'IMEI to Brand/Model/Name', value: 'service-11-table' },
      { label: 'XIAOMI MI LOCK & INFO (S2)', value: 'service-37-table' },
      { label: 'Samsung Info (S1) (IMEI)', value: 'service-8-table' },

      
    ],
  }),

  watch: {
    selectedService(value, oldValue) {
      if (! oldValue && !this.showOverlay) {
        this.showOverlay = true
      }
    },
    quickSearch(value) {
      if (!value && this._lastSearch) {
        this.doSearch()
      }
    },
  },

  mounted() {
    window.__S__ = this
  },

  methods: {
    onLoadedData() {
      if (this.showOverlay) {
        this.showOverlay = false
      }
    },
    
    doSearch() {
      this._lastSearch = this.quickSearch
      this.$refs.table.doSearch(this.quickSearch)
    },

    doCopy(payload) {
      const onError = () => this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Can not copy!',
          icon: 'BellIcon',
        },
      })
      if (! payload) {
        return onError()
      }
      let { data, col } = payload
      if (! data && !col) {
        return onError()
      }

      this.$copyText(data[col]).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        onError()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#selectAService {
  max-width: 600px;
  margin: auto;

  .vs__search {
    text-align: center;
  }
}

#searchForm {
  .input-group {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 769px) {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
  }
}
</style>